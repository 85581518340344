/* Misc stuff */
  .navtext {
    size: 5.2em;
  }

  html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  
  body {
    display: flex;
    flex-direction: column;
  }

/* Page layout */
  .app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
  }
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 40px);
    padding-bottom: 0;
  }

  .content > div {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .content-spacing {
  
    min-height: calc(100vh - 267px);
  }
  .no__padding {
    padding:0% !important;
  }

/* General CSS stuff */
  .navbar-logo-spacing {
    margin-left: 10%;
  }

  .navbar-signin-spacing {
    margin-right: 10%;
  }
  .navbar__brand {
    max-height: 32px;
  }
  .fill__width {
    width: 100%;
  }
  .rotate__element {
    transform: rotate(45deg);
    transition: transform 0.5s;
  }
  .flex__box__center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 768px) {
    .hidden__on__mobile {
      display: none !important;
    }
  }
  @media (min-width: 769px) {
    .only__shown__on__mobile {
      display: none !important;
    }
  }
  .collins__dark__background {
    background-color: #343a40 !important;
    color: #F1FAFF !important;
  }
  .collins__dark__background a {
    color: #F1FAFF !important;
  }
  .collins__dark__background a:hover {
    font-weight: bold;
  }
  .sticky {
    position: sticky;
    top: 0;
    z-index: 1000;
  }
  .btn-collins {
    background-color: white !important;
    outline: #6c757d;
    outline-color: #6c757d !important;
    color: #3B3131 !important;
  }
  .btn-collins:hover {
    background-color: #343a40 !important;
    color: #F1FAFF !important;
  }
  .btn-collins:active {
    outline-color: #6c757d;
  }
  .white__background {
    background-color: white;
  }
  .small__padding__top__bottom {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .grey__bottom__border {
    border-bottom: 1px solid #6c757d;
  }

/* Nav */



/* Loading */
  .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
  }
  .loading-container i {
    font-size: 4rem;
    color: #343a40 /* Customize the color as needed */
  }
  .loading-container p {
    margin-top: 10px;
    font-size: 1.2rem;
    color: #343a40; /* Customize the color as needed */
  }

/* Submitting */
.submitting-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 250px;
}
.submitting-container i {
  font-size: 4rem;
  color: #343a40 /* Customize the color as needed */
}
.submitting-container p {
  margin-top: 10px;
  font-size: 1.2rem;
  color: #343a40; /* Customize the color as needed */
}

/* Component Sampler */
  .component__sampler__header:hover {
    cursor: pointer;
  }
  .component__expansion__indicator {
    color:#6c757d;
  }
  .component__content {
    overflow: hidden;
    transition: height 0.3s ease;
    padding: 0 10px;
  }


/* News / Notes */
  .notes__title {
    color: #fff;
    font-size: 38px;
    font-family: "Montserrat";
    font-weight: 400;
  }
  .notes {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(3, minmax(300px, 1fr));
  }
  @media screen and (max-width: 900px) {
    .notes {
        grid-template-columns: repeat(2, minmax(300px, 1fr));
    }
  }
  @media screen and (max-width: 500px) {
    .notes {
        grid-template-columns: repeat(1, minmax(300px, 1fr));
    }
  }
  .note {
    background: rgba(108, 117, 125, 0.13);
    backdrop-filter: blur(5px);
    box-shadow: inset -6px -4px 2px rgba(52, 58, 64, 0.479);
    border-radius: 15px;
    border: 1.5px solid rgba(52, 58, 64, 0.603);
    color: #3d4042;
    padding: 15px;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    word-wrap: break-word;
    margin-bottom: 10px;
  }
  .note__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0px;
    margin-top:0px;
  }
  .note textarea {
    white-space: pre-wrap;
    background: transparent;
    border: none;
    color: #000000;
    resize: none;
    font-size: 18px;
  }
  .note textarea:focus {
    outline: none;
  }
  .note textarea::placeholder {
    color: #000000;
  }
  .note__save {
    background: transparent;
    transition: 0.1s ease-in-out;
    border: 1.5px solid #000000;
    border-radius: 10px;
    color: #000000;
    padding: 4px 10px;
    font-size: 13px;
    cursor: pointer;
  }
  .note__save:hover {
    background: #000000;
    border: 1.5px solid #000000;
    color: #9e9ba1;
  }
  .note__edit {
    cursor: pointer;
    margin-right: 10px;
  }
  .note__edit:hover {
    transform: scale(1.1);
    color: blue;
  }
  .note__delete {
    cursor: pointer;
  }
  .note__delete:hover {
    transform: scale(1.1);
    color: red;
  }
  .note__cancel__edit {
    cursor: pointer;
    margin-right: 10px;
  }
  .note__cancel__edit:hover {
    transform: scale(1.1);
  }
  .note__confirm__edit {
    cursor: pointer;
  }
  .note__confirm__edit:hover {
    transform: scale(1.1);
    color: green;
  }
  .char__progress {
    background-color: #000000!important;
  }
  .note__written__by {
    color:#5b6063;
    font-style: italic;
    font-size: xx-small;
    display: inline-block;
  }
  .note__separator {
    margin-top: 5px;
    margin-bottom: 5px;
  }

/* IT Admin User Management */
  .user-management-edit {
    margin-right: 10px;
  }

  .user-management-icon-disabled {
    opacity: 20%;
  }

/* Footer Styles */
  .footer {
    position: relative;
    bottom: 0;
    width: 100%;
    height: 40px;
    background-color: #212529;
    border-top: 1px solid #343a40;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 100;
  }
  
  .footer-text {
    margin: 0;
    color: #ffffff;
    font-size: 0.875rem;
  }

  .long-content {
    background-color: #808080;
    width: 100%;
    height: 100%;
  }

/* Calendar Styles & form control */
  .fc-event-title.fc-sticky {
    font-weight: normal;
    font-size: 10px;
  }
  .fc-day-past {
      background-color:#edebeb;
  }
  .fc-event-past {
      opacity: .5;
  }
  .fc-day-today {
      background: #f2a79f !important;
  }
  .fc-button.fc-button-primary {
      background: #6c757d;
  }
  .fc-button.fc-button-primary:disabled {
      background: #6c757d;
      opacity: .4;
  }
  .fc-button.fc-button-primary:hover {
      background: #343a40;
  }
  .fc-event-title {
      white-space: normal !important;
      align-items: normal !important;
  }
  .checkbox-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
  }
  .ui-checkbox {
      padding: 5px;
  }
  .fc-daygrid-day-number {
    color: inherit; /* Change this to the color you want */
    text-decoration: none; /* Remove underline */
}

.fc-daygrid-day-top {
    color: #6c757d; /* Change this to the color you want */
}
.fc-col-header-cell.fc-day a {
  color: #343a40 !important; /* Use this to set the text color you prefer */
  text-decoration: none !important; /* Remove underline */
  cursor: default; /* Change cursor to default to avoid link-like behavior */
  pointer-events: none; /* Disable click events if the default behavior is not desired */
}

  

  /* Hide the default checkbox appearance */
.form-check-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Custom checkbox appearance */
.form-check-label {
  position: relative;
  padding-left: 30px; /* Space for the custom checkbox */
  cursor: pointer;
  user-select: none; /* Prevent text selection */
}

/* Custom checkbox design */
.form-check-label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border: 2px solid #6c757d; /* Border color */
  border-radius: 4px; /* Rounded corners */
  background-color: white; /* Background color */
}

/* Checkbox checked state */
.form-check-input:checked + .form-check-label::before {
  background-color: #6c757d; /* Checkbox color when checked */
  border-color: #6c757d;
}

/* Checkmark inside the custom checkbox */
.form-check-input:checked + .form-check-label::after {
  content: "";
  position: absolute;
  left: 7px;
  top: 6px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/**/
/* Wrapper to position the select and caret */
.select-wrapper {
  position: relative;
  display: inline-block; /* Adjust width as needed */
  width: 100%; /* Or any specific width */
}

/* Basic styling for the select dropdown */
.form-control.filter.fill__width {
  background-color: #6c757d; /* Background color */
  color: white; /* Text color */
  border: 1px solid #6c757d; /* Border color to match the background */
  border-radius: 4px; /* Rounded corners */
  padding: 8px 40px 8px 12px; /* Padding with extra space for the caret */
  font-size: 16px; /* Font size for readability */
  appearance: none; /* Remove default styling */
  -webkit-appearance: none; /* Remove default styling for WebKit browsers */
  -moz-appearance: none; /* Remove default styling for Mozilla browsers */
  width: 100%; /* Make sure it fills the width of its container */
  cursor: pointer; /* Pointer cursor on hover */
  text-align: center; /* Center text */
}

/* Custom arrow (caret) styling */
.select-wrapper::after {
  content: '▼'; /* Downward-pointing caret */
  position: absolute;
  right: 12px; /* Position from the right edge */
  top: 50%;
  transform: translateY(-50%); /* Center vertically */
  color: white; /* Arrow color */
  font-size: 16px; /* Arrow size */
  pointer-events: none; /* Prevent interaction with the arrow */
}

/* Media query to remove side margins on mobile devices */
@media (max-width: 768px) {
  .content-spacing {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}