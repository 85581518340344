.chat-widget-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999;
  }
  
  .chat-button-style {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 16px;
  }
  
  .chat-window-style {
    width: 500px; /* Increased width for two-column layout */
    height: 400px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
  }
  
  .chat-header-style {
    background-color: #007bff;
    color: #fff;
    padding: 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .close-button-style {
    background: transparent;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 14px;
  }
  
  .chat-body-style {
    flex: 1;
    display: flex;
    overflow: hidden;
  }
  
  .chat-sessions-menu {
    width: 150px;
    border-right: 1px solid #ccc;
    overflow-y: auto;
  }
  
  .session-item {
    padding: 10px;
    cursor: pointer;
  }
  
  .session-item.active {
    background-color: #007bff;
    color: #fff;
  }
  
  .chat-session-view {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
  }
  
  .chat-input {
    padding: 10px;
    display: flex;
    gap: 10px;
  }
  