.create-service-order-container {
  max-width: 900px;
  padding: 0 15px;
}

.service-order-card {
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.service-order-card .card-header {
  background-color: #f8f9fa;
  border-bottom: 1px solid #e3e6f0;
  padding: 15px 20px;
}

.breadcrumb-nav {
  font-size: 0.9rem;
  color: #6c757d;
}

.breadcrumb-link {
  color: #007bff;
  text-decoration: none;
}

.breadcrumb-link:hover {
  text-decoration: underline;
}

.breadcrumb-separator {
  margin: 0 8px;
  color: #6c757d;
}

.breadcrumb-current {
  font-weight: 500;
  color: #6c757d;
}

.form-label {
  font-weight: 500;
  display: flex;
  align-items: center;
}

.customer-search-input {
  border-radius: 4px;
  border: 1px solid #ced4da;
  padding-right: 40px; /* Space for loading spinner */
}

.customer-search-input:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.customer-search-results {
  position: absolute;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  z-index: 1000;
  border: 1px solid #ddd;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.customer-result-item {
  cursor: pointer;
  padding: 10px 15px;
}

.customer-result-item:hover {
  background-color: #f8f9fa;
}

.customer-name {
  font-weight: 500;
}

.customer-location {
  font-size: 0.85rem;
  color: #6c757d;
}

.no-results-message {
  position: absolute;
  width: 100%;
  padding: 10px 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: #6c757d;
  z-index: 1000;
}

.selected-customer-card {
  border: 1px solid #e3e6f0;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin-bottom: 15px;
}

.selected-customer-card .card-body {
  padding: 15px;
}

.customer-details {
  font-size: 0.85rem;
}

.status-badge {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.75rem;
  font-weight: 600;
}

.status-active {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.status-inactive {
  background-color: #ffebee;
  color: #c62828;
}

.terms-badge {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 12px;
  background-color: #e3f2fd;
  color: #1565c0;
  font-size: 0.75rem;
  font-weight: 600;
}

.description-textarea {
  resize: vertical;
  border-radius: 4px;
  border: 1px solid #ced4da;
}

.description-textarea:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.submit-button, .cancel-button {
  border-radius: 4px;
  padding: 8px 16px;
  font-weight: 500;
}

.submit-button {
  min-width: 180px;
}

/* Mobile responsiveness */
@media (max-width: 767px) {
  .service-order-card .card-header {
    padding: 12px 15px;
  }
  
  .form-label {
    margin-bottom: 8px;
  }
  
  .customer-search-results {
    max-height: 240px;
  }
  
  .submit-button, .cancel-button {
    width: 100%;
    margin-bottom: 10px;
  }
  
  .d-flex.justify-content-end {
    flex-direction: column-reverse;
  }
  
  .me-2 {
    margin-right: 0 !important;
  }
}

/* Reset radio button styling completely */
.radio-custom-container {
  position: relative;
  display: flex;
  gap: 1rem;
}

/* Hide any duplicate or extra form controls */
.radio-custom-container .form-check input[type="radio"] + input[type="radio"],
.radio-custom-container .form-check input[type="radio"] ~ div input[type="radio"] {
  display: none !important;
}

/* Ensure only one radio control is visible */
.radio-custom-container .form-check {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 0 !important;
  margin-bottom: 0.5rem;
}

.radio-custom-container .form-check input[type="radio"] {
  appearance: radio !important;
  -webkit-appearance: radio !important;
  -moz-appearance: radio !important;
  position: static !important;
  margin-right: 8px !important;
  margin-left: 0 !important;
  margin-top: 0 !important;
  border: none !important;
  background: none !important;
  box-shadow: none !important;
}

.radio-custom-container .form-check-label {
  margin-left: 0 !important;
  display: inline-block !important;
}

/* Fix for mobile alignment */
@media (max-width: 767px) {
  .radio-custom-container {
    flex-direction: column;
  }
  
  .radio-custom-container .form-check {
    margin-bottom: 12px;
    width: 100%;
  }
  
  .radio-custom-container .form-check input[type="radio"] {
    flex-shrink: 0;
  }
}

/* Fix duplicate radio controls */
input[type="radio"]#estimate-type.form-check-input,
input[type="radio"]#service-order-type.form-check-input {
  /* Make the default Bootstrap radio control the only visible one */
  display: inline-block !important;
  appearance: radio !important;
  -webkit-appearance: radio !important;
  position: relative !important;
  margin-right: 8px !important;
  z-index: 2;
}

/* Hide any duplicate controls that Bootstrap might be generating */
.form-check-input + .form-check-input,
.form-check > div > input[type="radio"],
input[type="radio"].form-check-input ~ input[type="radio"] {
  display: none !important;
}

/* Ensure proper spacing of the visible radio button */
.form-check {
  display: flex !important;
  align-items: center !important;
  margin-bottom: 10px !important;
}

/* Proper label positioning */
.form-check-label {
  margin-left: 0 !important;
  position: relative !important;
  z-index: 1;
}

/* Fix mobile radio button layout */
@media (max-width: 767px) {
  .radio-custom-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .form-check {
    width: 100%;
    margin-right: 0;
  }
}

/* Enhanced touch-friendly radio styling */
.radio-container {
  display: flex;
  gap: 1.5rem;
  margin-bottom: 0.5rem;
}

.custom-radio-item {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  padding: 6px 0;
}

/* Make entire area clickable by extending label to cover the whole space */
.custom-radio-item label {
  margin-bottom: 0;
  padding: 8px 8px 8px 28px;
  cursor: pointer;
  position: relative;
  z-index: 1;
  border-radius: 4px;
  transition: background-color 0.2s;
}

/* Hide the default radio button */
.custom-radio-item input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.custom-radio-item label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border: 2px solid #007bff;
  border-radius: 50%;
  background-color: white;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.custom-radio-item label:after {
  content: "";
  position: absolute;
  display: none;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #007bff;
}

/* Style the indicator when checked */
.custom-radio-item input:checked ~ label:after {
  display: block;
}

/* Hover effect */
.custom-radio-item:hover label {
  background-color: rgba(0, 123, 255, 0.1);
}

/* Disabled state */
.custom-radio-item input:disabled ~ label {
  opacity: 0.6;
  cursor: not-allowed;
}

.custom-radio-item input:disabled ~ label:before {
  border-color: #6c757d;
  background-color: #e9ecef;
}

/* Mobile styling with larger touch targets */
@media (max-width: 767px) {
  .radio-container {
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }
  
  .custom-radio-item {
    width: 100%;
  }
  
  .custom-radio-item label {
    display: block;
    width: 100%;
    padding: 12px 12px 12px 36px;
    font-size: 16px;
  }
  
  .custom-radio-item label:before {
    width: 24px;
    height: 24px;
    left: 6px;
  }
  
  .custom-radio-item label:after {
    width: 14px;
    height: 14px;
    left: 11px;
  }
}

/* Section styling for better visual grouping */
.form-section {
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #e9ecef;
}

.form-section:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.section-header {
  margin-bottom: 1.25rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #f8f9fa;
}

.section-header h4 {
  margin: 0;
  color: #495057;
  font-size: 1.25rem;
  font-weight: 500;
}

/* Customer card styling */
.selected-customer-card {
  background-color: #f8f9fa;
  border-left: 4px solid #007bff;
}

.branch-display {
  font-weight: 500;
  color: #495057;
  padding: 0.5rem;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.status-badge {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.75rem;
  font-weight: 600;
}

.status-active {
  background-color: #d4edda;
  color: #155724;
}

.status-inactive {
  background-color: #f8d7da;
  color: #721c24;
}

.terms-badge {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.75rem;
  background-color: #e2e3e5;
  color: #383d41;
}

/* Submit section */
.submit-section {
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 2px solid #f8f9fa;
}

/* Mobile-specific improvements */
@media (max-width: 767px) {
  .form-section {
    padding: 1rem;
    margin-bottom: 1rem;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  }
  
  .section-header {
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
  }
  
  .section-header h4 {
    font-size: 1.1rem;
  }
  
  .form-label {
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
  
  .service-order-card {
    border: none;
    box-shadow: none;
  }
  
  .card-body {
    padding: 0.5rem;
  }
  
  .submit-section {
    position: sticky;
    bottom: 0;
    background-color: white;
    padding: 1rem;
    box-shadow: 0 -2px 10px rgba(0,0,0,0.1);
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    margin-bottom: -0.5rem;
    border-radius: 0 0 8px 8px;
  }
  
  .submit-button, .cancel-button {
    padding: 0.75rem 1rem;
    font-size: 1rem;
  }
}

/* Keep existing radio styling */
