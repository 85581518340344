.left-side-menu {
  position: fixed;
  top: 60px; /* Adjust based on your header height */
  left: 0;
  /* Extend height to exactly meet the footer */
  height: calc(100vh - 60px - 40px); /* Subtract header and footer heights */
  background-color: #f8f9fa;
  border-right: 1px solid #dee2e6;
  border-top-right-radius: 8px; /* Add radius to top right */
  border-bottom-right-radius: 8px; /* Add radius to bottom right */
  overflow-y: auto;
  z-index: 50;
  transition: transform 0.3s ease, width 0.3s ease;
  width: 250px;
  padding: 15px 10px;
  /* Remove margin-bottom - we're using exact height calculation instead */
  margin-bottom: 0;
  /* Add drop shadow with light source from top left */
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15);
}

.left-side-menu.collapsed {
  transform: translateX(-250px);
  width: 0;
  padding: 0;
}

.left-side-menu.expanded {
  transform: translateX(0);
}

/* Add fading separator line between menu sections */
.menu-section:not(:last-child) {
  position: relative;
  margin-bottom: 23px; /* Keep the increased spacing */
}

.menu-section:not(:last-child)::after {
  content: "";
  position: absolute;
  bottom: -12px; /* Position in the middle of the margin space */
  left: 10%;
  right: 10%;
  height: 1px;
  background: linear-gradient(
    to right,
    rgba(222, 226, 230, 0), /* Transparent at edges */
    rgba(222, 226, 230, 0.7) 50%, /* Visible in center */
    rgba(222, 226, 230, 0) /* Transparent at edges */
  );
}

.menu-header {
  display: flex;
  align-items: center;
  font-weight: bold;
  padding: 8px 10px;
  margin-top: 15px;
  margin-bottom: 5px;
  border-bottom: 1px solid #dee2e6;
  text-align: left; /* Ensure left alignment */
}

.menu-header i {
  margin-right: 10px;
  color: #dc3545; /* Collins red - Bootstrap's danger color */
}

.submenu {
  padding-left: 0; /* Remove default padding */
}

.menu-item {
  margin-bottom: 5px;
}

.menu-link {
  display: flex;
  align-items: center;
  padding: 8px 10px;
  color: #212529;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.2s;
  text-align: left; /* Ensure left alignment */
}

.menu-link:hover {
  background-color: #e9ecef;
}

.menu-link i {
  margin-right: 10px; /* Space between icon and text */
  width: 16px; /* Fixed width for icons to align text */
  text-align: center; /* Center the icon in its fixed width */
}

.main-link {
  font-weight: 600;
  color: #dc3545; /* Collins red color */
}

.main-link:hover {
  color: #c82333;
}

/* Active state */
.menu-link.active {
  background-color: #e2e6ea;
  color: #dc3545;
  font-weight: 500;
}

/* Search styles */
.search-container {
  margin-bottom: 15px;
}

.search-box {
  position: relative;
  display: flex;
  align-items: center;
}

.search-icon {
  position: absolute;
  left: 10px;
  color: #6c757d;
}

.search-input {
  padding: 8px 30px 8px 30px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  width: 100%;
}

.search-input:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  outline: none;
}

.clear-search {
  position: absolute;
  right: 10px;
  border: none;
  background: none;
  cursor: pointer;
  color: #6c757d;
}

.clear-search:hover {
  color: #dc3545;
}

.search-results {
  margin-top: 10px;
}

.search-highlight {
  background-color: rgba(255, 255, 0, 0.3);
  font-weight: bold;
}

.no-results {
  padding: 10px;
  color: #6c757d;
  text-align: center;
}

.category-tag {
  font-size: 0.75rem;
  color: #6c757d;
  margin-left: 5px;
}

.menu-toggle-button {
  position: fixed;
  top: 120px; /* Moved down 50px from 70px */
  left: 0;
  z-index: 51;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-left: none;
  border-radius: 0 4px 4px 0;
  padding: 10px 8px;
  cursor: pointer;
  transition: left 0.3s ease;
  /* Similar shadow for consistency */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.menu-toggle-button.expanded {
  left: 250px;
}

.menu-toggle-button:hover {
  background-color: #e9ecef;
}

/* Update the main content area to adjust when menu is expanded/collapsed */
.home-container {
  transition: margin-left 0.3s ease;
  margin-left: 0;
}

/* When menu is expanded, push content to the right */
.left-side-menu.expanded + .home-container {
  margin-left: 250px;
}

@media (max-width: 768px) {
  .left-side-menu {
    display: none;
  }
}

/* Better word wrapping */
.menu-link span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Add padding to bottom of the scrollable area to ensure last items are visible */
.left-side-menu:after {
  content: "";
  display: block;
  height: 20px; /* Reduced to provide space but not create a gap */
}
