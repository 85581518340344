.device-audit-container {
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
  }
  
  .device-column, .browser-column {
    max-width: 100px;
    text-align: center;
  }
  
  .table td {
    vertical-align: middle;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
  
  .table th {
    position: sticky;
    top: 0;
    background-color: #f8f9fa;
    z-index: 1;
    white-space: nowrap;
    text-align: center;
  }
  
  .table th:nth-child(1) { width: 8%; } /* User ID */
  .table th:nth-child(2) { width: 30%; } /* Username */
  .table th:nth-child(3) { width: 20%; } /* Last Accessed */
  .table th:nth-child(4) { width: 10%; } /* Device Type */
  .table th:nth-child(5) { width: 10%; } /* Browser */
  .table th:nth-child(6) { width: 5%; } /* Loc */
  .table th:nth-child(7) { width: 17%; } /* Location */
  
  .table-responsive {
    overflow-x: auto;
    max-height: 70vh;
  }

  .table th, .table td {
    padding: 0.75rem;
  }

  .table td:nth-child(7) {
    text-align: left;
  }