/* Mobile Footer Base */
.mobile-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: #ffffff;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.mobile-footer-bar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 8px 0;
    border-top: 1px solid #e0e0e0;
}

.footer-icon-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #495057;
    text-decoration: none;
    background: none;
    border: none;
    padding: 10px 0;
    font-size: 12px;
    flex-grow: 1;
    cursor: pointer;
    transition: all 0.2s;
}

.footer-icon-button i {
    font-size: 18px;
    margin-bottom: 5px;
    color: #555;
}

.footer-icon-button:hover,
.footer-icon-button:active {
    color: #dc3545; /* Collins red */
}

.footer-icon-button:hover i,
.footer-icon-button:active i {
    color: #dc3545;
}

.menu-button {
    background-color: #f8f9fa;
    border-radius: 5px;
}

/* Mobile Menu Overlay */
.mobile-menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1100;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
}

.mobile-menu-overlay.open {
    visibility: visible;
    opacity: 1;
}

.mobile-menu {
    position: fixed;
    width: 85%;
    max-width: 350px;
    height: 100%;
    top: 0;
    right: 0;
    background-color: #ffffff;
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.2);
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.mobile-menu-overlay.open .mobile-menu {
    transform: translateX(0);
}

.mobile-menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: #dc3545; /* Collins red */
    color: white;
}

.user-info {
    display: flex;
    align-items: center;
}

.user-info i {
    font-size: 22px;
    margin-right: 10px;
}

.close-menu {
    background: none;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
}

.mobile-search {
    padding: 15px;
    border-bottom: 1px solid #e0e0e0;
}

.mobile-search .search-box {
    position: relative;
    display: flex;
    align-items: center;
}

.mobile-search .search-box i {
    position: absolute;
    left: 10px;
    color: #6c757d;
}

.mobile-search input {
    width: 100%;
    padding: 10px 35px 10px 35px;
    border: 1px solid #ced4da;
    border-radius: 20px;
    font-size: 14px;
}

.mobile-search .clear-search {
    position: absolute;
    right: 10px;
    background: none;
    border: none;
    color: #6c757d;
    cursor: pointer;
}

.mobile-menu-content {
    flex: 1;
    overflow-y: auto;
    padding: 10px 0;
}

/* Category Styling */
.menu-category {
    margin-bottom: 10px;
}

.category-header {
    display: flex;
    align-items: center;
    padding: 15px;
    background-color: #f8f9fa;
    border-left: 4px solid #dc3545; /* Collins red */
    cursor: pointer;
    font-weight: 600;
}

.category-header i:first-child {
    margin-right: 10px;
    width: 20px;
    color: #dc3545; /* Collins red */
}

.category-header span {
    flex: 1;
}

.toggle-icon {
    font-size: 12px;
    color: #6c757d;
}

.home-link {
    display: flex;
    align-items: center;
    padding: 15px;
    background-color: #f8f9fa;
    border-left: 4px solid #dc3545;
    text-decoration: none;
    color: #212529;
    font-weight: 600;
}

.home-link i {
    margin-right: 10px;
    width: 20px;
    color: #dc3545;
}

.category-link:hover {
    background-color: #e9ecef;
}

/* Menu Items */
.menu-items {
    background-color: #ffffff;
}

.menu-item {
    border-bottom: 1px solid #f1f1f1;
}

.menu-link {
    display: flex;
    align-items: center;
    padding: 15px 15px 15px 45px;
    text-decoration: none;
    color: #495057;
    position: relative;
}

.menu-link:hover {
    background-color: #f8f9fa;
    color: #dc3545;
}

.menu-link i {
    width: 20px;
    margin-right: 10px;
    color: #6c757d;
}

.menu-link:hover i {
    color: #dc3545;
}

/* Search Results */
.menu-search-results h3 {
    padding: 15px;
    margin: 0;
    font-size: 16px;
    color: #495057;
    border-bottom: 1px solid #e0e0e0;
}

.category-label {
    font-size: 11px;
    background-color: #f1f1f1;
    color: #6c757d;
    padding: 2px 8px;
    border-radius: 10px;
    margin-left: auto;
}

.no-results {
    padding: 20px;
    text-align: center;
    color: #6c757d;
    font-style: italic;
}

/* Prevent body scrolling when menu is open */
body.menu-open {
    overflow: hidden;
}

/* Avoid content being hidden behind fixed footer */
.content {
    padding-bottom: 60px;
}
