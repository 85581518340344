.weather-container {
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  color: #333;
}

.weather-loading,
.weather-error,
.location-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  color: #6c757d;
}

.weather-loading i,
.weather-error i {
  font-size: 24px;
  margin-bottom: 10px;
}

.location-error {
  background-color: #f8f9fa;
  border-radius: 6px;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 0.9rem;
}

/* Current Weather Styles */
.current-weather {
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}

.current-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.current-header h3 {
  margin: 0;
  font-size: 1.3rem;
  font-weight: 600;
  color: #333;
}

.current-date {
  color: #666;
  font-size: 0.9rem;
}

.current-details {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.temp-icon {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.weather-icon {
  width: 50px;
  height: 50px;
}

.current-temp {
  font-size: 2.2rem;
  font-weight: 700;
  margin-left: 5px;
}

.weather-description {
  display: flex;
  flex-direction: column;
}

.weather-main {
  font-weight: 600;
  margin: 0;
  font-size: 1.1rem;
}

.weather-desc {
  color: #666;
  margin: 0;
  font-size: 0.9rem;
  text-transform: capitalize;
}

.weather-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  margin-top: 15px;
}

.detail-item {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}

.detail-item i {
  margin-right: 8px;
  width: 15px;
  color: #dc3545;
}

/* Forecast Styles */
.forecast {
  padding-top: 15px;
}

.forecast h4 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 1.1rem;
  color: #333;
}

.forecast-items {
  display: flex;
  overflow-x: auto;
  padding-bottom: 5px;
  scrollbar-width: thin;
}

.forecast-items::-webkit-scrollbar {
  height: 5px;
}

.forecast-items::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.forecast-items::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 10px;
}

.forecast-day {
  min-width: 80px;
  text-align: center;
  padding: 10px 8px;
  border-right: 1px solid #eee;
}

.forecast-day:last-child {
  border-right: none;
}

.forecast-date {
  margin: 0 0 5px;
  font-size: 0.85rem;
  white-space: nowrap;
}

.forecast-icon {
  width: 40px;
  height: 40px;
  margin: 0 auto 5px;
}

.forecast-temps {
  display: flex;
  justify-content: center;
  gap: 8px;
  font-size: 0.9rem;
}

.max-temp {
  font-weight: 600;
}

.min-temp {
  color: #6c757d;
}

@media (max-width: 480px) {
  .weather-details {
    grid-template-columns: 1fr;
  }
}
