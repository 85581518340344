.home-page-layout {
    display: flex;
    min-height: calc(100vh - 60px); /* Adjust based on your navbar height */
}

.home-container {
    position: relative;
    flex: 1;
    margin-left: 270px; /* Width of the left menu */
    min-height: calc(100vh - 60px);
    overflow: hidden;
    transition: margin-left 0.3s ease;
}

.mobile-container {
    margin-left: 0;
    padding: 0 15px;
}

.welcome-content {
    padding: 2rem;
    position: relative;
    z-index: 2;
}

.background-logo {
    position: fixed;
    bottom: -100px;
    right: -400px;
    width: 200%;
    max-width: 2400px;
    opacity: 0.40;
    z-index: 1;
    pointer-events: none;
    transform: rotate(10deg);
}

/* Make the logo more visible on hover for an interactive effect */
.home-container:hover .background-logo {
    opacity: 0.50; /* Increased from 0.25 */
    transition: opacity 0.5s ease;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .home-container {
        margin-left: 0;
    }
    
    .welcome-content {
        padding: 1rem;
    }
    
    .background-logo {
        opacity: 0.3;
        right: -300px;
    }
}

.menu-expanded {
    margin-left: 250px; /* Match the width of the expanded menu */
}

/* Adjust for mobile */
@media (max-width: 768px) {
    .menu-expanded {
        margin-left: 0; /* No margin on mobile */
    }
}
