/* Existing styles */

/* New Bullseye Button */
.bullseye-button {
  background-color: #ff0000 !important; /* Red background */
  color: #ffffff;                       /* White bullseye icon */
  border: none;
  border-radius: 4px;                   /* Slightly rounded corners */
  width: 40px;                          /* Button width */
  height: 40px;                         /* Button height */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 8px;                     /* Space between buttons */
  padding: 0;                           /* Remove default padding */
}

.bullseye-button:hover {
  background-color: #cc0000; /* Darker red on hover */
}

.bullseye-button:disabled {
  background-color: #ffcccc; /* Light red when disabled */
  cursor: not-allowed;
}

.bullseye-icon {
  /* Additional styling if needed */
  /* Example: */
  transform: scale(3); /* Scales the icon 1.5 times its original size */
}

/* Add these styles for the loading overlay */
.page-loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-spinner-container {
  text-align: center;
}

.loading-spinner {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #0066cc;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 0 auto 20px;
}

.loading-text {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Add this for disabled rows */
.service-order-row.disabled {
  pointer-events: none;
}

